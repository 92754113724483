/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 268px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 69.87951807228914%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABYBAQEBAAAAAAAAAAAAAAAAAAMBBP/aAAwDAQACEAMQAAABlom0l6AtX//EABoQAAMBAAMAAAAAAAAAAAAAAAECAxIAIiP/2gAIAQEAAQUCGmtSLpOXZDLRM3PF8x//xAAYEQADAQEAAAAAAAAAAAAAAAAAERIBAv/aAAgBAwEBPwHaZw5P/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAIhMf/aAAgBAgEBPwGktf/EABoQAQACAwEAAAAAAAAAAAAAAAEAEQISYSH/2gAIAQEABj8C0EI57iHJbLue58lM/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARMSFRYYH/2gAIAQEAAT8hIr7lnyDDcHEzIt+R6oBPJZdLD8Su87yf/9oADAMBAAIAAwAAABA43//EABkRAAIDAQAAAAAAAAAAAAAAAAARASExkf/aAAgBAwEBPxCLt6VGf//EABgRAAMBAQAAAAAAAAAAAAAAAAABESHR/9oACAECAQE/EFhcIqH/xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhMVGh/9oACAEBAAE/EIbqCOHXmduwhXQ1rfcgK+LBrIbig7cDBQFJR4kPhklmzQ1vP//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"wyoming flag\"\n        title=\"wyoming flag\"\n        src=\"/static/fd37bfff42ba58643c23b1c623b2b46d/a5025/wyoming-flag.jpg\"\n        srcset=\"/static/fd37bfff42ba58643c23b1c623b2b46d/4d5fb/wyoming-flag.jpg 166w,\n/static/fd37bfff42ba58643c23b1c623b2b46d/a5025/wyoming-flag.jpg 268w\"\n        sizes=\"(max-width: 268px) 100vw, 268px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The area of what is now known as Wyoming was inhabited by the Algonquin-speaking Munsee Indians.  They named the land ", React.createElement("i", null, "xwé:wam"), React.createElement("i", null, "ə"), React.createElement("i", null, "nk "), "which means “at the big river flat” which later evolved into the name Wyoming."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
